import React from "react";

const NoSolution = () => {
  return (
    <>
      <h2> There are no solutions!</h2>
      <img src="images/sad_jerry.jpg" style={{ height: "150px" }} />
    </>
  );
};

export default NoSolution;
