import React from "react";
import "../css/LandingPageMain.css";

const LandingPageMain = () => {
  return (
    <>
      <div className="landingPage-main">RatVenture</div>
    </>
  );
};

export default LandingPageMain;
