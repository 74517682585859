import "./App.css";
import AllPages from "./pages/AllPages";

function App() {
  return (
    <>
      <AllPages />
    </>
  );
}

export default App;
