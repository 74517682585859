import React from "react";
import LandingPageDesign from "./components/jsx/LandingPageDesign";

const LandingPage = () => {
  return (
    <>
      <LandingPageDesign />
    </>
  );
};

export default LandingPage;
